import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
  Inter typescript communication
  https://angularfirebase.com/lessons/sharing-data-between-angular-components-four-methods/#data-service-ts
*/
/*
 Company Change....
 1. MeterBox Name below
 2. index.html file metatag
 3. CompileType_DEBUG is optional
 */ 
//export var MeterBox_Name: string = "Access Manager";
export var MeterBox_Name: string = "MeterBox";

@Injectable()
export class GlobalProvider {
    readonly WORKDAY_MARKER = "WD";
    readonly TYPE_NONE = 0;
    readonly TYPE_CHCHEALTHCARE = 1;
    readonly TYPE_GREYOFFICE = 2;
    readonly TYPE_GINGERTRACKER = 3;
    CompileType: number = this.TYPE_NONE;
    //-------------------------------------------------------
    readonly SHOW_CONSOLE = 0;
    CompileType_DEBUG: number = this.TYPE_CHCHEALTHCARE;
    //CompileType_DEBUG: number = this.TYPE_GREYOFFICE;
    //-------------------------------------------------------
    private messageSource = new BehaviorSubject(null);
    //private messageSource = new ReplaySubject();
    public menuKey: string = "Users,";
    currentMessage = this.messageSource.asObservable();
    globalSearch: string="";
    messageTimesCount: number = 0;
    messageLeavesCount: number = 0;
    StaffSelected: string = "";
    DefaultDashboard: string = "/dashboard";
    ledgerFilter: number = 0;
    detailsFilter: number = 1;
    iImageRandamize: number = 0;
    public month_name: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //------------------------------------
    iYearActive: number = 0;
    iMonthActive: number = 0;
    //-------------------------HR Report Activities
    m_Roster: string = "";
    m_Team: string = "";
    m_Bank: string = "";
    //m_Shift: string = "";
    m_Staff: BreakItem;
    //-------------------------Have to be replaced by online query later
    sarLoaded: boolean= false;
    sarTitles: string[]=[];
    sarRolls: string[] = [];
    sarTeams: string[] = [];
    sarBases: string[] = [];
    sarBands: string[] = [];
    sarGroups: string[] = [];
    sarGrades: string[] = [];
    sarPayscales: PayscalDropdownItem[] = [];
    sarBanks: Bank[] = [];
    sarRosterOptions: string[] = [];
    sarRosters: string[] = [];
    /*
    sarDesignations: string[] = [null, 'Trainee', 'ECG Analyst Trainee', 'ECG Analyst', 'Sr.ECG Analyst',
        'Floor Supervisor', 'Team Leader', 'Manager', 'Sr.Manager'];
    sarRolls: string[] = [null, 'Techs', 'Supervisor', 'Team Leader', 'Manager', 'Admin', 'Super Admin'];
    sarTeams: string[] = [null, 'LEADERSHIP', 'CENTURY', 'MSA', 'ACT-IR/ELITE', 'PHILIPS-LW',
        'ROZINN-ACT EX', 'DMS-AUS', 'PCS-CA', 'AMI-USA', 'HW-myPatch', 'CLINICAL EDUCATION',
        'CLINICAL OPERATION/BD', 'CUSTOMER SUPPORT-DOMESTIC', 'BIOMEDICAL SALES/SERVICE',
        'FIELD SUPPORT', 'HRD & ADMINSTRATION', 'FINANCE & ACCOUNTS', 'INFORMATION TECHNOLOGY',
        'CEO OFFICE', 'VENDOR STAFF-SECURITY', 'VENDOR STAFF-HOUSE KEEPING'];
    */
    //-------------------------Have to be replaced by online query later END
    GlobalProvider() {
        //this.menuKey = "Users,";
        
    }
    changeMessage(message: string) {
        this.messageSource.next(message)
    }
    public EraseAll() {
        this.globalSearch = "";
        this.menuKey = "";
        this.StaffSelected = "";
        this.m_Roster = "";
        this.m_Team = "";
        this.m_Staff = { "key": "", "value": "" };

        this.sarLoaded = false;
        if (this.sarTitles)this.sarTitles.length = 0;
        if (this.sarRolls)this.sarRolls.length = 0;
        if (this.sarTeams)this.sarTeams.length = 0;
        if (this.sarBases)this.sarBases.length = 0;
        if (this.sarBands)this.sarBands.length = 0;
        if (this.sarGroups)this.sarGroups.length = 0;
        if (this.sarGrades)this.sarGrades.length = 0;
        if (this.sarPayscales)this.sarPayscales.length = 0;
        if (this.sarBanks) this.sarBanks.length = 0;
        if (this.sarRosterOptions) this.sarRosterOptions.length = 0;
    }
    public UpdateDomain() {
        
        var arr = window.location.hostname.split(".");
        //var arr = "www.GingerTracker.com".split(".");
        if (arr.length == 1) APIDomain = arr[0]; // Ex. localhost
        else if (arr.length == 2) { //  Ex. www.localhost or domain.com
            if (arr[1].toLowerCase() == "com") {
                APIDomain = "api." + arr[0] + "." + arr[1];
            } else {
                APIDomain = arr[0] + "." + arr[1];
            }
        } else if (arr.length == 3) { //  Ex. www.domain.com
            APIDomain = "api." + arr[1] + "." + arr[2];
        } else if (arr.length > 3) {
            APIDomain = window.location.hostname;
        }
        APIDomain = "hicuapi.homeicu.com" //commented on 21-05-2024
        //Testing Starts for AWS by Sivaguru M CHC1704 on 21-05-2024
        //APIDomain = "apiaws.chchealthcare.in";

        //APIDomain = "api.chchealthcare.in";//Added on 08-06-2024 by Sivaguru M CHC1704

        //Testing Ends
         //APIDomain = "chg-healthwatch-1983770325.ap-south-1.elb.amazonaws.com:56000";//this is for while publish on the server
        APIDomain = APIDomain.toLowerCase();

        //APIDomain="api.shiffin.com"
        //APIDomain = "localhost:56000"
        //APIDomain = "192.168.1.10";
        //APIDomain = "localhost"
        //APIDomain ="api.chchealthcare.in"
        //-----------------delete above
        MyPort = "";
        if (window.location.port != '80') MyPort = window.location.port;
        wwwDomain = window.location.hostname;
        //_________________________________________
        Head_Domain = wwwDomain;
        if (wwwDomain.toLowerCase().indexOf("gingertracker") > -1) {
            Head_Name = "Ginger Tracker";
            this.DefaultDashboard = "/dashboard";
            WEB_FRONT_TEXT_FULL = "Live GPS Tracking. Taxi Dispatch and Fleet Management Solutions.";
            this.DefaultDashboard = "/dashboard";
            this.CompileType = this.TYPE_GINGERTRACKER;

        } else if (wwwDomain.toLowerCase().indexOf("trilliontaxi") > -1) {
            Head_Name = "Trillion Taxi";
            this.DefaultDashboard = "/dashboard";
            this.CompileType = this.TYPE_GINGERTRACKER;

        } else if (wwwDomain.toLowerCase().indexOf("chchealthcare") > -1){
            Head_Name = "Meter Box";
            WEB_FRONT_TEXT = "Meter Box";
            WEB_FRONT_TEXT_FULL = "";//"HR, Payroll, Attendance and Leave Management & Terminal Monitoring.";
            this.DefaultDashboard = "/dashboard_accessmanager";
            this.CompileType = this.TYPE_CHCHEALTHCARE;
        } else if (wwwDomain.toLowerCase().indexOf("greyoffice") > -1) {
            Head_Name = "Meter Box";
            WEB_FRONT_TEXT = "Meter Box";
            WEB_FRONT_TEXT_FULL = "";//"HR, Payroll, Attendance and Leave Management & Terminal Monitoring.";
            this.DefaultDashboard = "/dashboard_accessmanager";
            this.CompileType = this.TYPE_GREYOFFICE;
        } else {    // Must be testing localhost
            if (this.CompileType_DEBUG === this.TYPE_GINGERTRACKER) {
                Head_Name = "Ginger Tracker";
                WEB_FRONT_TEXT = "Ginger Tracker";
                WEB_FRONT_TEXT_FULL = "Live GPS Tracking. Taxi Dispatch and Fleet Management Solutions.";
                this.DefaultDashboard = "/dashboard";
                this.CompileType = this.TYPE_GINGERTRACKER;
            } else if (this.CompileType_DEBUG === this.TYPE_GREYOFFICE) {
                    Head_Name = "Grey Office";
                    WEB_FRONT_TEXT = "Grey Office";
                    WEB_FRONT_TEXT_FULL = "";//"HR, Payroll, Attendance and Leave Management & Terminal Monitoring.";
                    this.DefaultDashboard = "/dashboard_accessmanager";
                this.CompileType = this.TYPE_GREYOFFICE;
            } else {
                Head_Name = "Meter Box";
                WEB_FRONT_TEXT = "Meter Box";
                WEB_FRONT_TEXT_FULL = "";//"HR, Payroll, Attendance and Leave Management & Terminal Monitoring.";
                this.DefaultDashboard = "/dashboard_accessmanager";
                this.CompileType = this.TYPE_CHCHEALTHCARE;
            }
        }
        
    }
    /*
        12-5-2019 
     */ 
    public IsDate(str) {
        var ar = str.split("-");
        if (ar.length == 3) {
            var month = parseInt(ar[1]);
            if (!isNaN(month)) {
                if (month > 0) {
                    month -= 1;
                    var datum = new Date(Date.UTC(parseInt(ar[2]), month, parseInt(ar[0])));
                    if ((datum.getDate() === parseInt(ar[0])) && (datum.getMonth() === month) && (datum.getFullYear() === parseInt(ar[2]))) {
                        return datum;
                    }
                }
            }
        }
        return null;
    }
    public GetLogo() {
        if (this.CompileType === this.TYPE_GINGERTRACKER) {
            return "./assets/logos/gingertracker_48.png";
        } else if (this.CompileType === this.TYPE_CHCHEALTHCARE) {
            return "./assets/logos/healthwatch_50.png";
        } else {
            return "./assets/logos/greyoffice.png";            
        }
    }
    GetEmpty() {
        return "./assets/logos/empty20.png";
    }
    GetLogoAM() {
        if (this.CompileType === this.TYPE_GINGERTRACKER) {
            return "./assets/logos/empty.png";
        } else if (this.CompileType === this.TYPE_CHCHEALTHCARE) {
            return "./assets/logos/meterbox_healthwatch.png";
            
        } else {
            return "./assets/logos/greyoffice_accessmanager.png";
        }
    }
    GetLogo256() {
        if (this.CompileType === this.TYPE_GINGERTRACKER) {
            return "./assets/logos/gingertracker_logo_64.png";
        } else if (this.CompileType === this.TYPE_GREYOFFICE) {
            return "./assets/logos/greyoffice_logo_256.png";
        } else {
            return "./assets/logos/hw1024.png";
        }
    }
}
//-----------------------------------------------------------------
export var WEB_FRONT_TEXT: string = "";
export var WEB_FRONT_TEXT_FULL: string = "";
//-----------------------------------------------------------------
export var Head_Name: string = "";
export var Head_Domain: string = "";
//-----------------------------------------------------------------
//export var DefaultDashboard: string = "/dashboard";
//-----------------------------------------------------------------
export var APIDomain: string = "";
export var wwwDomain: string = "";
export var MyPort: string = "";
export interface JobMessage {
    Mode: string;
    JobID: string;
    StaffID: string;
    DriverIMEI: string;
    DvrName: string;
    RegNo: string;
    TaxiType: string;
}
export interface HubObject {
    Mode: string;
    sData: string;
    sMess: string;
    lData: number;
}
export interface SubObj {
    comm: number;
    str: string;
    obj: object;
}

interface BreakItem {
    key: string;
    value: string;
}
export interface PayscalDropdownItem {
    m_Name: string,
    m_Key: number,
    m_StartDate: number,
    m_Amount: number,
}
export interface Bank {
    m_Name: string,
    m_Branch: string,
    m_IFSC: string,
}
export interface FixedArrayResponse {
    status: boolean;
    result: string;
    sarTitles: string[];
    sarRolls: string[];
    sarTeams: string[];
    sarBases: string[];
    sarBands: string[];
    sarGrades: string[];
    sarPayscales: PayscalDropdownItem[];
    sarBanks: Bank[];
    sarRosterOptions: string[];
    sarGroups: string[];
}
export interface PostResponse {
    status: boolean;
    result: string;
    iParam1: number;
}
export const wait: string = "<span style='color:blue;font-weight:bold'>Wait...</span>";
